<template>
    <v-bottom-navigation

        app
        horizontal
        dark
        color="primary" class="white--text"
    >
        <v-btn text class="white--text" value="orderlist" @click.stop="navigate('OrderList')">
            <span>Order list</span>
            <v-icon>list</v-icon>
        </v-btn>

        <v-btn text class="white--text" value="map" @click.stop="navigate('Map')">
            <span>map</span>
            <v-icon>navigation</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    name: "Footer",
    data: () => ({}),
    methods: {
        navigate(name) {
            this.$router.push({name: name})
        }
    },
}
</script>

<style scoped>

</style>